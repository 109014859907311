import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <defs>
      <clipPath id="a">
        <path fill="none" d="M21.17 46.81L18 64h24l2.75-17.19H21.17z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <g>
        <g>
          <g>
            <path
              fill="#72b8d4"
              d="M32.29 47.2l.29.82a.29.29 0 00.41.17l.79-.37a.3.3 0 01.4.4l-.37.79a.29.29 0 00.17.41l.82.29a.31.31 0 010 .58l-.82.29a.29.29 0 00-.17.41l.37.79a.3.3 0 01-.4.4l-.78-.37a.29.29 0 00-.41.17l-.29.82a.31.31 0 01-.58 0l-.3-.8a.29.29 0 00-.41-.17l-.79.37a.3.3 0 01-.4-.4l.37-.79a.29.29 0 00-.17-.41l-.82-.29a.31.31 0 010-.58l.82-.29a.29.29 0 00.17-.41l-.37-.79a.3.3 0 01.4-.4l.79.37a.29.29 0 00.41-.17l.29-.82a.31.31 0 01.58-.02z"
            />
            <animateTransform
              attributeName="transform"
              dur="9s"
              repeatCount="indefinite"
              type="rotate"
              values="0 32 50; 180 32 50; 360 32 50"
            />
          </g>
          <animateTransform
            attributeName="transform"
            dur="3s"
            repeatCount="indefinite"
            type="translate"
            values="-3 0; 3 0"
          />
        </g>
        <animateTransform
          attributeName="transform"
          dur="3s"
          repeatCount="indefinite"
          type="translate"
          values="2 -6; -2 12"
        />
        <animate attributeName="opacity" dur="3s" repeatCount="indefinite" values="1;1;1;0" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="#72b8d4"
              d="M39.29 47.2l.29.82a.29.29 0 00.41.17l.79-.37a.3.3 0 01.4.4l-.37.79a.29.29 0 00.17.41l.82.29a.31.31 0 010 .58l-.82.29a.29.29 0 00-.17.41l.37.79a.3.3 0 01-.4.4l-.78-.37a.29.29 0 00-.41.17l-.29.82a.31.31 0 01-.58 0l-.3-.8a.29.29 0 00-.41-.17l-.79.37a.3.3 0 01-.4-.4l.37-.79a.29.29 0 00-.17-.41l-.82-.29a.31.31 0 010-.58l.82-.29a.29.29 0 00.17-.41l-.37-.79a.3.3 0 01.4-.4l.79.37a.29.29 0 00.41-.17l.29-.82a.31.31 0 01.58-.02z"
            />
            <animateTransform
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="rotate"
              values="0 39 50; 180 39 50; 360 39 50"
            />
          </g>
          <animateTransform
            attributeName="transform"
            begin="-1s"
            dur="3s"
            repeatCount="indefinite"
            type="translate"
            values="0 0; 3 0"
          />
        </g>
        <animateTransform
          attributeName="transform"
          begin="-1s"
          dur="3s"
          repeatCount="indefinite"
          type="translate"
          values="2 -6; -2 12"
        />
        <animate
          attributeName="opacity"
          begin="-1s"
          dur="3s"
          repeatCount="indefinite"
          values="1;1;1;0"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="#72b8d4"
              d="M25.29 47.2l.29.82a.29.29 0 00.41.17l.79-.37a.3.3 0 01.4.4l-.37.79a.29.29 0 00.17.41l.82.29a.31.31 0 010 .58l-.82.29a.29.29 0 00-.17.41l.37.79a.3.3 0 01-.4.4l-.78-.37a.29.29 0 00-.41.17l-.29.82a.31.31 0 01-.58 0l-.3-.8a.29.29 0 00-.41-.17l-.79.37a.3.3 0 01-.4-.4l.37-.79a.29.29 0 00-.17-.41l-.82-.29a.31.31 0 010-.58l.82-.29a.29.29 0 00.17-.41l-.37-.79a.3.3 0 01.4-.4l.79.37a.29.29 0 00.41-.17l.29-.82a.31.31 0 01.58-.02z"
            />
            <animateTransform
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="rotate"
              values="0 25 50; 180 25 50; 360 25 50"
            />
          </g>
          <animateTransform
            attributeName="transform"
            begin="-1.5s"
            dur="3s"
            repeatCount="indefinite"
            type="translate"
            values="-3 0; 2 0"
          />
        </g>
        <animateTransform
          attributeName="transform"
          begin="-1.5s"
          dur="3s"
          repeatCount="indefinite"
          type="translate"
          values="2 -6; -2 12"
        />
        <animate
          attributeName="opacity"
          begin="-1.5s"
          dur="3s"
          repeatCount="indefinite"
          values="1;1;1;0"
        />
      </g>
    </g>
    <path
      fill="#efefef"
      stroke="#efefef"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M46.5 31.5h-.32a10.49 10.49 0 00-19.11-8 7 7 0 00-10.57 6 7.21 7.21 0 00.1 1.14A7.5 7.5 0 0018 45.5a4.19 4.19 0 00.5 0v0h28a7 7 0 000-14z"
    />
  </svg>
);
