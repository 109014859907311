import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g>
      <path
        fill="none"
        stroke="#efefef"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M43.64 20a5 5 0 113.61 8.46h-35.5M29.14 44a5 5 0 103.61-8.46h-21"
      />
      <animateTransform
        attributeName="transform"
        dur="2s"
        repeatCount="indefinite"
        type="translate"
        values="-8 2; 0 -2; 8 0; 0 1; -8 2"
      />
    </g>
  </svg>
);
